a {
  text-decoration: none;
  color: silver;
}

a:hover {
  color: #f3f3f3;
}

.panel {
  border: 1px solid #242424;
  border-radius: 3px;
  background-color: #f8f9f9;
}

.dragAndDropPlaceDisabled {
  border-style: dashed;
  border-radius: 3px;
  cursor: pointer;
  border-color: #b3b3b3;
  color: #b6b4b4;
  background-color: #f0efef;
}

.dragAndDropPlaceDisabled:hover {
  border-color: #999999;
  color: #818181;
  background-color: #f5f5f5;
}

.dragAndDropPlaceEnabled {
  border-style: dashed;
  border-radius: 3px;
  cursor: pointer;
  border-color: #3b3b3b;
  color: #3b3b3b;
  background-color: #f8f8f8;
}

.header {
  background-color: #242424;
  color: #dddddd;
}

img {
  border: 1px solid gray;
  border-radius: 2px;
}

.imgContainer {
  border: 1px solid black;
  border-radius: 2px;
}

.icon {
  width: auto;
  max-height: 200px;
}

.responsive-canvas {
  max-width: 100%;
  min-width: 100%;
}

.notifications-panel {
  position: fixed;
  right: 0px;
  top: 0px;
  margin: 0rem;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.notification {
  opacity: 0.8;
}

.notification:hover {
  opacity: 1;
}

@media only screen and (min-width: 576px) {
  .notifications-panel {
    width: 80%;
    margin: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .notifications-panel {
    width: 75%;
  }
}

@media only screen and (min-width: 992px) {
  .notifications-panel {
    width: 65%;
  }
}

@media only screen and (min-width: 1200px) {
  .notifications-panel {
    width: 50%;
  }
}

@media only screen and (min-width: 1400px) {
  .notifications-panel {
    width: 40%;
  }
}
